import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import moment from 'moment';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactTooltip from 'react-tooltip';
import useDispatchNotification from '../../../hooks/notifications/useDispatchNotification';
import { useLocalStorage } from '../../../hooks/storage/useLocalStorage';
import {
    getDataInReports,
    getReportsByUserTpye,
} from '../../../services/adminService';
import { formatNumber } from '../../../utils/functions';
import CustomSelect from '../../Input/CustomSelect/CustomSelect';
import SelectWithSearch from '../../Input/SelectWithSearch/SelectWithSearch';
import DropdownPeriods from '../../Select/DropdownPeriods';
import ReportsByUserType from './ReportsByUserType/ReportsByUserType';

const initialData = {
    currencies: {},
    totals: {},
    reports: {},
    spentByUsers: {},
};
const Reports = ({ isActive }) => {
    const { warningNotification } = useDispatchNotification();
    const [isLoading, setIsLoading] = useState(true);
    const [dataByUserType, setDataByUserType] = useState(initialData);
    const [dataForSearch, setDataForSearch] = useState(initialData);
    const tableTranslations = 'sections.management.section.reports';
    const { t } = useTranslation();
    const advertisers = 'advertisers';
    const publishers = 'publishers';
    const [userType, setUserType] = useState(advertisers);
    const isData = Object.keys(dataByUserType.reports).length !== 0;
    const debounceRef = useRef();
    const [currencyCurrent, setCurrencyCurrent] = useState({});
    const nameCSV = `${userType}-${currencyCurrent.name}.csv`;
    const [selectedFilters, setSelectedFilters] = useState([]);
    const [subFilters, setSubFilters] = useState({});
    const [selectedSubFilters, setSelectedSubFilters] = useState({});
    const [isLoadingOptions, setIsLoadingOptions] = useState(false);
    const [currentPeriod, setCurrentPeriod] = useLocalStorage(
        'currentPeriodReports',
        [
            moment().format('YYYY-MM-DD 00:00:00'),
            moment().format('YYYY-MM-DD 23:59:00'),
        ]
    );
    const [value, setValue] = useLocalStorage('valueReports', 'today');

    const getStringFilters = (options) => {
        return Object.entries(options)
            .map(
                ([key, options]) =>
                    `${key}=${options.map((option) => option.id).join(',')}`
            )
            .join('&');
    };
    const fetchReportsByUsers = async (periods, filters) => {
        if (currentPeriod[0] == null && currentPeriod[1] == null) return;
        setIsLoading(true);
        const strignFilters = getStringFilters(filters);
        const reports = await getReportsByUserTpye(
            periods,
            userType,
            strignFilters
        );
        if (reports.error) {
            warningNotification(
                t('common.notifications.error.title'),
                t('common.notifications.error.errorData')
            );
            return;
        }
        let { dataCharts, traductionsCharts } = getDataCharts(reports);
        const newReports = { ...reports, dataCharts, traductionsCharts };
        const currencyByDefault = Object.values(reports.currencies)[0];
        setCurrencyCurrent(currencyByDefault || {});
        setDataByUserType(newReports);
        setDataForSearch(newReports);
        setIsLoading(false);
    };

    const getDataCharts = (reports) => {
        let newSpentByUser = {};
        const traduction1 = t(
            tableTranslations + '.users.user.spentByUsersPieChart'
        );
        Object.entries(reports.spentByUsers).forEach(([key, value]) => {
            newSpentByUser[key] = Object.values(value);
        });
        if (userType === advertisers) {
            const traduction2 = t(
                tableTranslations + '.users.user.spentByCampaignsPieChart'
            );
            const traduction3 = t(
                tableTranslations + '.users.user.impressionsByCampaignsPieChart'
            );
            return {
                dataCharts: [
                    newSpentByUser,
                    reports.spentByCampaigns,
                    reports.impressionsByCampaigns,
                ],
                traductionsCharts: [traduction1, traduction2, traduction3],
            };
        } else {
            let spentByScreens = {};
            let impressionsByScreens = {};
            Object.entries(reports.spentByScreens).forEach(([key, value]) => {
                spentByScreens[key] = Object.values(value);
            });
            Object.entries(reports.impressionsByScreens).forEach(
                ([key, value]) => {
                    impressionsByScreens[key] = Object.values(value);
                }
            );
            const traduction2 = t(
                tableTranslations + '.users.user.spentByScreensPieChart'
            );
            const traduction3 = t(
                tableTranslations + '.users.user.impressionsByScreensPieChart'
            );
            return {
                dataCharts: [
                    newSpentByUser,
                    spentByScreens,
                    impressionsByScreens,
                ],
                traductionsCharts: [traduction1, traduction2, traduction3],
            };
        }
    };

    useEffect(() => {
        fetchReportsByUsers(currentPeriod, selectedSubFilters);
    }, [currentPeriod[0], currentPeriod[1], userType]);

    const customSort = (rowA, rowB, nameRow) => {
        if (rowA[nameRow] > rowB[nameRow]) return 1;
        if (rowB[nameRow] > rowA[nameRow]) return -1;
        return 0;
    };

    // TODO: separate to component
    const headerWithCurrency = (text, currencyShortName) => {
        return (
            <p>
                <b>{text}</b>
                <span
                    style={{
                        color: '#bdbdbd',
                        fontSize: 10,
                    }}>
                    &nbsp;({currencyShortName})
                </span>
            </p>
        );
    };

    const columns = useMemo(
        () => [
            {
                minWidth: '12%',
                name: t('sections.management.section.users.users.user'),
                exportCSV: true,
                label: t('sections.management.section.users.users.user'),
                key: 'userId',
                maxWidth: userType == publishers ? '30%' : '15%',
                selector: (row) => row.userId,
                cell: (row) => (
                    <div className="">
                        <div className="max-h-12 overflow-hidden">
                            <p className="line-clamp-2">{row.username}</p>
                        </div>
                        <div>
                            <p className=" opacity-50">{row.userId}</p>
                        </div>
                    </div>
                ),
                sortable: true,
            },
            {
                minWidth: '8%',
                name: t(tableTranslations + '.users.user.publisher'),
                exportCSV: true,
                label: t(tableTranslations + '.users.user.publisher'),
                key: 'publisher',
                maxWidth: userType == publishers ? '30%' : '15%',
                selector: (row) => row.publisher,
                sortable: true,
            },
            {
                minWidth: '8%',
                name: t(tableTranslations + '.users.campaigns.id'),
                exportCSV: true,
                label: t(tableTranslations + '.users.campaigns.id'),
                key: 'campaingId',
                selector: (row) => row.campaingId,
                sortable: true,
            },
            {
                minWidth: '13%',
                name: t(tableTranslations + '.users.campaigns.campaignBrand'),
                exportCSV: true,
                label: t(tableTranslations + '.users.campaigns.campaignBrand'),
                key: 'campaignBrand',
                selector: (row) => row.campaignBrand,
                cell: (row) => (
                    <div className=" whitespace-pre-line">
                        <p>{row.campaignBrand}</p>
                    </div>
                ),
                sortable: true,
            },
            {
                minWidth: '20%',
                name: t(tableTranslations + '.users.campaigns.campaignName'),
                exportCSV: true,
                label: t(tableTranslations + '.users.campaigns.campaignName'),
                key: 'campaignName',
                selector: (row) => row.campaignName,
                cell: (row) => (
                    <div className="max-w-md relative">
                        <div className="group line-clamp-2">
                            <div data-tip={row.campaignName}>
                                <div className=" relative z-10">
                                    {row.campaignName}
                                </div>
                            </div>
                        </div>
                        <ReactTooltip />
                    </div>
                ),
                sortable: true,
            },

            {
                minWidth: '8%',
                compact: true,
                name: t(tableTranslations + '.users.screens'),
                exportCSV: true,
                label: t(tableTranslations + '.users.screens'),
                key: 'screenName',
                selector: (row) => row.screenName,
                cell: (row) => <div>{row.screenName}</div>,
                sortable: true,
            },
            {
                minWidth: '10%',
                name: t(tableTranslations + '.users.campaigns.impressions'),
                exportCSV: true,
                label: t(tableTranslations + '.users.campaigns.impressions'),
                key: 'impressions',
                selector: (row) => {
                    row.valueImpressions = row.impressions;
                    // *value footer come with row.key.props.children
                    return row.impressions?.props?.children ? (
                        <b>
                            {formatNumber(
                                row.impressions?.props?.children,
                                'withcommas'
                            )}
                        </b>
                    ) : (
                        formatNumber(row.impressions, 'withcommas')
                    );
                },
                sortable: true,
                sortFunction: (row1, row2) =>
                    customSort(row1, row2, 'valueImpressions'),
                right: true,
            },
            {
                minWidth: '11%',
                name: t(tableTranslations + '.users.campaigns.impacts'),
                exportCSV: true,
                label: t(tableTranslations + '.users.campaigns.impacts'),
                key: 'impacts',
                selector: (row) => {
                    row.valueImpacts = row.impacts;
                    // *value footer come with row.key.props.children
                    return row.impacts?.props?.children ? (
                        <b>
                            {formatNumber(
                                row.impacts?.props?.children,
                                'rounded'
                            )}
                        </b>
                    ) : (
                        formatNumber(row.impacts, 'rounded')
                    );
                },
                sortable: true,
                sortFunction: (row1, row2) =>
                    customSort(row1, row2, 'valueImpacts'),
                right: true,
            },
            {
                minWidth: '10%',
                compact: true,
                name: headerWithCurrency(
                    t(tableTranslations + '.users.campaigns.spent'),
                    currencyCurrent.short_name
                ),
                exportCSV: true,
                label: t(tableTranslations + '.users.campaigns.spent'),
                key: 'spent',
                selector: (row) => {
                    row.valueSpent = row.spent;
                    // *value footer come with row.key.props.children
                    return row.spent?.props?.children ? (
                        <b>
                            {currencyCurrent.symbol}
                            {formatNumber(
                                row.spent?.props?.children,
                                'withcommas'
                            )}
                        </b>
                    ) : (
                        `${row.symbol}${formatNumber(row.spent, 'withcommas')}`
                    );
                },
                sortable: true,
                sortFunction: (row1, row2) =>
                    customSort(row1, row2, 'valueSpent'),
                right: true,
            },
            {
                minWidth: '16%',
                name: headerWithCurrency(
                    t(tableTranslations + '.users.campaigns.cpm-label'),
                    currencyCurrent.short_name
                ),
                exportCSV: true,
                label: t(tableTranslations + '.users.campaigns.cpm-label'),
                key: 'ecpm',
                selector: (row) => {
                    row.valueEcpm = row.ecpm;
                    // *value footer come with row.key.props.children
                    return row.ecpm?.props?.children ? (
                        <b>
                            {currencyCurrent.symbol}
                            {formatNumber(
                                row.ecpm.props?.children,
                                'withcommas'
                            )}
                        </b>
                    ) : (
                        `${row.symbol}${formatNumber(row.ecpm, 'withcommas')}`
                    );
                },
                sortable: true,
                sortFunction: (row1, row2) =>
                    customSort(row1, row2, 'valueEcpm'),
                right: true,
            },
        ],
        [dataByUserType.reports, currencyCurrent]
    );

    const calculateTotals = (dataBySearch) => {
        let totals = {
            spent: 0,
            ecpm: 0,
            impressions: 0,
            impacts: 0,
        };
        dataBySearch.forEach((campaign) => {
            let { spent, impressions, impacts } = campaign;
            totals.spent += spent;
            totals.impressions += impressions;
            totals.impacts += impacts;
        });

        totals.ecpm = (totals.impressions / totals.spent) * 1000;
        return totals;
    };

    const searchByNameTable = async (word, currencyName) => {
        if (debounceRef.current) {
            clearInterval(debounceRef.current);
        }

        debounceRef.current = setTimeout(async () => {
            if (value.trim() !== '') {
                let wordToSearch = word.toLowerCase().trim();

                let dataBySearch;

                if (userType === publishers) {
                    dataBySearch = dataForSearch.reports[currencyName].filter(
                        (user) =>
                            user.publisher.toLowerCase().includes(wordToSearch)
                    );
                } else if (userType === advertisers) {
                    dataBySearch = dataForSearch.reports[currencyName].filter(
                        (user) =>
                            user.username.toLowerCase().includes(wordToSearch)
                    );
                }

                const totals = calculateTotals(dataBySearch);

                setDataByUserType({
                    ...dataForSearch,
                    reports: {
                        ...dataForSearch.reports,
                        [currencyName]: dataBySearch,
                    },
                    totals: { ...dataForSearch.totals, [currencyName]: totals },
                });
            }
        }, 400);
    };

    const options = [
        {
            id: 1,
            label: t(tableTranslations + '.users.filters.users'),
            value: 'users',
            isSelected: false,
        },
        {
            id: 2,
            label: t(tableTranslations + '.users.filters.brands'),
            value: 'brands',
            isSelected: false,
        },
    ];

    useEffect(() => {
        if (selectedFilters.length === 0) return;
        const dataToGet = selectedFilters.map((filter) => filter.value);
        setIsLoadingOptions(true);
        getDataInReports(dataToGet).then((res) => {
            setSubFilters(res);
            setIsLoadingOptions(false);
        });
    }, [selectedFilters]);

    const handleClickOption = (option) => {
        if (
            selectedFilters.find(
                (optionSelected) => optionSelected.id === option.id
            )?.isSelected
        )
            return;
        const newFilters = [
            ...selectedFilters,
            { ...option, isSelected: true },
        ];
        setSelectedFilters(newFilters);
    };

    const removeOption = (option) => {
        const newFilters = selectedFilters.filter(
            (optionLoop) => optionLoop.id !== option.id
        );
        const newSelectedSubfilters = {
            ...selectedSubFilters,
            [option.value]: [],
        };
        setSelectedSubFilters(newSelectedSubfilters);
        fetchReportsByUsers(currentPeriod, newSelectedSubfilters);
        setSelectedFilters(newFilters);
    };

    return (
        <div className="flex divide-x divide-dotted bg-white">
            <nav className={`${isActive ? '' : 'hidden'} flex flex-col`}>
                <button
                    id="reportsByAdvertisers"
                    onClick={() => setUserType(advertisers)}
                    className={`${
                        userType == advertisers
                            ? 'tabActiveV font-semibold truncate'
                            : ''
                    } tabGeneral uppercase text-xs text-right truncate`}>
                    <span className="hidden md:inline-block">
                        {t('sections.management.section.reports.advertisers')}
                    </span>
                </button>
                <button
                    id="reportsByPublishers"
                    onClick={() => setUserType(publishers)}
                    className={`${
                        userType == publishers
                            ? 'tabActiveV font-semibold truncate'
                            : ''
                    } tabGeneral uppercase text-xs text-right truncate`}>
                    <span className="hidden md:inline-block">
                        {t('sections.management.section.reports.publishers')}
                    </span>
                </button>
                {/* )} */}
            </nav>
            <div
                className={`${
                    isActive ? 'block' : 'hidden'
                }  bg-opacity-90 rounded-b-md p-4  w-full`}>
                <div className="grid grid-cols-1 mb-4">
                    <div className=" flex place-content-end gap-2 ">
                        <div className="flex gap-2">
                            {selectedFilters.map((filter, key) => {
                                return (
                                    <SelectWithSearch
                                        key={filter.label}
                                        options={subFilters[filter.value]}
                                        label={filter.label}
                                        onClickClose={() =>
                                            removeOption(filter)
                                        }
                                        handleClick={(optionsSelected) => {
                                            setSelectedSubFilters({
                                                ...selectedSubFilters,
                                                [filter.value]: optionsSelected,
                                            });
                                        }}
                                        handleRefresh={() =>
                                            fetchReportsByUsers(
                                                currentPeriod,
                                                selectedSubFilters
                                            )
                                        }
                                        isLoading={isLoading}
                                        isLoadingOptions={isLoadingOptions}
                                    />
                                );
                            })}
                            <CustomSelect
                                label={t(
                                    'sections.campaigns.new-campaign.wizard-screens.filters.filters-label'
                                )}
                                options={options}
                                firstIcon={'filter'}
                                handleClick={handleClickOption}
                            />
                        </div>
                        <DropdownPeriods
                            handleDropdownPeriods={'usersReports'}
                            setCurrentPeriod={setCurrentPeriod}
                            currentPeriod={currentPeriod}
                            value={value}
                            setValue={setValue}
                        />
                    </div>
                    {isLoading ? (
                        <div className="w-full flex whitespace-nowrap">
                            <div className="mx-auto">
                                <FontAwesomeIcon
                                    icon={['fas', 'circle-notch']}
                                    className="text-blue-400 animate-spin"
                                />
                            </div>
                        </div>
                    ) : (
                        <ReportsByUserType
                            isData={isData}
                            columns={
                                userType == 'publishers'
                                    ? columns.filter(
                                          (colum) =>
                                              colum.key !== 'campaignName' &&
                                              colum.key !== 'campaingId' &&
                                              colum.key !== 'userId'
                                      )
                                    : columns.filter(
                                          (colum) =>
                                              colum.key !== 'screenName' &&
                                              colum.key !== 'publisher'
                                      )
                            }
                            dataColumns={dataByUserType.reports}
                            currencies={dataByUserType.currencies}
                            totals={dataByUserType.totals}
                            onSearch={searchByNameTable}
                            isLoading={isLoading}
                            keyForTotals={
                                userType == publishers ? 'username' : 'userId'
                            }
                            dataCharts={dataByUserType.dataCharts}
                            traductionsCharts={dataByUserType.traductionsCharts}
                            currencyCurrent={currencyCurrent}
                            setCurrencyCurrent={setCurrencyCurrent}
                            nameCSV={nameCSV}
                            userType={userType}
                        />
                    )}
                </div>
            </div>
        </div>
    );
};

export default Reports;
