import useCurrencySpace from '../../../../../hooks/budget/useCurrencySpaces';
import { useEffect, useRef } from 'react';
import { screensActions } from '../../../../../store';
import {
    formatNumber,
    isNumber,
    isValidInput,
    numberWithoutCommas,
} from '../../../../../utils/functions';

export const MaxCpmInput = ({
    newCampaign,
    dispatch,
    maxCpm,
    t,
    loadingDataTable,
    setLoadingDataTable,
    applyFilters,
}) => {
    const debounceRef = useRef();

    //debounce for avoid aply filters excessively
    useEffect(() => {
        if (maxCpm === null) return;
        if (debounceRef.current) {
            clearInterval(debounceRef.current);
        }

        debounceRef.current = setTimeout(() => {
            let screensFilteredByAll = applyFilters();
            dispatch(
                screensActions.setScreensFilteredFromScreensWizard(
                    screensFilteredByAll
                )
            );
        }, 400);
    }, [maxCpm]);

    const selectedCurrency = newCampaign?.selectedCurrency?.symbol;

    const currencySpace = useCurrencySpace(selectedCurrency);

    const handleChangeMaxCpm = (amount) => {
        if (isValidInput(amount, 'positiveNumber')) {
            amount = numberWithoutCommas(amount);
            dispatch(screensActions.setMaxCpm(amount));
        } else if (!amount) {
            dispatch(screensActions.setMaxCpm(0));
        }
    };
    return (
        <div className="w-full flex flex-col">
            <label htmlFor="maxCpm" className="block title font-light mt-2">
                {t(
                    'sections.campaigns.new-campaign.wizard-screens.filters.cpm'
                )}
            </label>
            <div className="mt-1 relative rounded-md shadow-sm max-w-32">
                <span className="absolute left-2 top-2 title font-light">
                    {newCampaign?.selectedCurrency &&
                        newCampaign.selectedCurrency.symbol}
                </span>
                <div className="w-36 flex flex-col">
                    <input
                        type="text"
                        name="maxCpm"
                        id="maxCpm"
                        value={
                            maxCpm !== null
                                ? formatNumber(maxCpm, 'withcommas')
                                : ''
                        }
                        min="0"
                        autoComplete="off"
                        onChange={(e) => handleChangeMaxCpm(e.target.value)}
                        className={`w-full focus:ring-0 focus:outline-none block sm:text-sm rounded-md border-gray-300 ${currencySpace} mb-3`}
                        placeholder={'0.00'}
                    />
                </div>
            </div>
        </div>
    );
};
