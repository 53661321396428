import React, { useEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import DataTable from 'react-data-table-component';
import UserModal from './UserModal/UserModal';
import { useService } from '../../../../../containers/UseService';
import { CSVLink } from 'react-csv';
import CommonButton from '../../../../Buttons/CommonButton';
import SwitcherButton from '../../../../Buttons/CommonSwitcher';
import SwitcherButton2 from '../../../../Buttons/SwitcherButton2';
import { updateStatusUser } from '../../../../../services/adminService';
import { useDispatch } from 'react-redux';
import { notificationsActions } from '../../../../../store';

const UserList = (params) => {
    const { isActive } = params;
    const { t } = useTranslation();
    const service = useService();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [totalUsers, setTotalUsers] = useState([]);
    const csvLinkRef = useRef();
    const [downloadCSV, setDownloadCSV] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);
    const [searchValue, setSearchValue] = useState('');
    const [users, setUsers] = useState([]);
    const [isEditingUser, setIsEditingUser] = useState(false);
    const [user, setUser] = useState(null);
    const [isLoadingStatus, setIsLoadingStatus] = useState({
        id: undefined,
        loading: false,
    });
    const [currentSort, setCurrentSort] = useState({
        sortby: 'id',
        sortDirection: 'desc',
    });
    const toggleModalOpen = () => {
        setIsEditingUser(false);
        setUser(null);
        setIsModalOpen(!isModalOpen);
    };
    const toggleEditModalOpen = (user) => {
        setIsModalOpen(true);
        setUser(user);
        setIsEditingUser(true);
    };
    const tableTranslations =
        'sections.management.section.users.users.UserList.';
    const dispatch = useDispatch();

    const onSubmitUserModal = (newUser) => {
        if (newUser) {
            const isEdit = users.some((user) => user.id === newUser.id);
            const newUsers = isEdit
                ? users.map((user) => {
                      if (user.id === newUser.id) {
                          return newUser;
                      } else {
                          return user;
                      }
                  })
                : [newUser].concat([...users]);
            toggleModalOpen();
            setIsEditingUser(false);
            setUsers(newUsers);
        }
    };

    const handleInputSearchChange = (value) => setSearchValue(value);
    const handlePageChange = (page) => setCurrentPage(page);

    const handleStatusChange = async (id, status) => {
        setIsLoadingStatus({ id, loading: true });
        updateStatusUser(id, status)
            .then((res) => {
                if (res.status === 200 && res.data.error === false) {
                    let newUsers = JSON.parse(JSON.stringify(users));
                    newUsers.forEach((user) => {
                        if (user.id === id) {
                            user.status = !user.status;
                        }
                    });
                    // the state is then set so you have to sort it again by the order it was in
                    newUsers.sort((userA, userB) => {
                        if (
                            userA[currentSort.sortby] >
                            userB[currentSort.sortby]
                        ) {
                            let direction =
                                currentSort.sortDirection === 'desc' ? -1 : 1;
                            return direction;
                        }
                        if (
                            userB[currentSort.sortby] >
                            userA[currentSort.sortby]
                        ) {
                            let direction =
                                currentSort.sortDirection === 'desc' ? 1 : -1;
                            return direction;
                        }
                        return 0;
                    });
                    setUsers(newUsers);
                    dispatch(
                        notificationsActions.setNotification({
                            type: 'success',
                            title: `${
                                status
                                    ? `${t(
                                          'sections.management.section.users.users.user'
                                      )} ${t(
                                          'sections.management.section.users.users.update-status-blocked-success'
                                      )}`
                                    : `${t(
                                          'sections.management.section.users.users.user'
                                      )} ${t(
                                          'sections.management.section.users.users.update-status-unblocked-success'
                                      )}`
                            }`,
                            message: `${
                                status
                                    ? `${
                                          newUsers.find(
                                              (user) => user.id === id
                                          )?.username
                                      } ${t(
                                          'sections.management.section.users.users.update-status-blocked-success'
                                      )}`
                                    : `${
                                          newUsers.find(
                                              (user) => user.id === id
                                          )?.username
                                      } ${t(
                                          'sections.management.section.users.users.update-status-unblocked-success'
                                      )}`
                            }`,
                        })
                    );
                } else {
                    dispatch(
                        notificationsActions.setNotification({
                            type: 'warning',
                            title: t(
                                'sections.management.section.users.users.update-status-error'
                            ),
                            message: t(
                                'sections.management.section.users.users.update-status-error'
                            ),
                        })
                    );
                    console.error('error: ', res.data.message);
                }
            })
            .catch((err) => {
                console.log('err: ', err);
            })
            .finally(() => setIsLoadingStatus({ id, loading: false }));
    };

    const sortByStatus = (rowA, rowB) => {
        const statusA = rowA.status;
        const statusB = rowB.status;
        if (statusA > statusB) return 1;
        if (statusB > statusA) return -1;
        return 0;
    };

    const columns = [
        {
            name: t(tableTranslations + 'edit'),
            exportCSV: false,
            cell: (row) => (
                <div className={`w-20 flex flex-nowrap items-center`}>
                    <span className="mr-2">
                        <CommonButton
                            onClick={() => toggleEditModalOpen(row)}
                            label={t(
                                'sections.management.section.users.users.editTitle'
                            )}
                            styleType={'smallprimary'}
                        />
                    </span>
                </div>
            ),
            width: '99px',
        },
        {
            name: t(tableTranslations + 'id'),
            exportCSV: true,
            key: 'id',
            selector: (row) => row.id,
            sortable: true,
        },
        {
            name: t(tableTranslations + 'username'),
            exportCSV: true,
            label: t(tableTranslations + 'username'),
            key: 'username',
            selector: (row) => row.username,
            sortable: true,
        },
        {
            name: t(tableTranslations + 'email'),
            exportCSV: true,
            label: t(tableTranslations + 'email'),
            key: 'email',
            selector: (row) => row.email,
            sortable: true,
        },
        {
            name: t(tableTranslations + 'company'),
            exportCSV: true,
            label: t(tableTranslations + 'company'),
            key: 'company',
            selector: (row) => row.company,
            sortable: true,
        },
        {
            name: t(tableTranslations + 'margin'),
            exportCSV: true,
            label: t(tableTranslations + 'margin'),
            key: 'rtb_margin',
            selector: (row) => row.rtb_margin,
            sortable: true,
        },
        {
            name: t(tableTranslations + 'role'),
            exportCSV: true,
            label: t(tableTranslations + 'role'),
            key: 'role',
            selector: (row) => row.role,
            sortable: true,
        },
        {
            name: 'rebate',
            exportCSV: true,
            label: 'rebate',
            key: 'rebate',
            selector: (row) => row.rebate,
            sortable: true,
        },
        {
            name: t(tableTranslations + 'status'),
            exportCSV: true,
            selector: (row) => row.status,
            cell: (row) => {
                return isLoadingStatus.id == row.id &&
                    isLoadingStatus.loading ? (
                    <FontAwesomeIcon
                        icon={['fas', 'circle-notch']}
                        className="text-blue-800 animate-spin mx-auto"
                    />
                ) : (
                    <SwitcherButton2
                        value={row.status}
                        handleClick={() =>
                            handleStatusChange(row.id, row.status)
                        }
                        labelOn={t(
                            'sections.management.section.users.users.active'
                        )}
                        labelOff={t(
                            'sections.management.section.users.users.inactive'
                        )}
                        revertLabel={true}
                    />
                );
            },
            label: t(tableTranslations + 'status'),
            sortable: true,
            sortFunction: (row1, row2) => sortByStatus(row1, row2),
            key: 'status',
            width: '125px',
        },
    ];

    const fetchUsers = async (page) => {
        setIsLoading(true);

        const data = await service.getUsers(searchValue, perPage, page);
        const newUsers = data.data.map((usr) => {
            return { ...usr, isBlocked: false };
        });

        setUsers(newUsers);
        setTotalRows(data.total);
        setIsLoading(false);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        setIsLoading(true);

        const data = await service.getUsers('', newPerPage, page);

        setUsers(data.data);
        setPerPage(newPerPage);
        setIsLoading(false);
    };

    useEffect(() => {
        fetchUsers(currentPage);
    }, [currentPage, searchValue]);

    const downloadToCSV = async () => setTotalUsers(await service.getUsers());

    useEffect(() => {
        if (totalUsers.length > 0) {
            setDownloadCSV(true);
        }
    }, [totalUsers]);

    useEffect(() => {
        if (downloadCSV) csvLinkRef.current.link.click();
    }, [downloadCSV]);

    const exportCSVButton = useMemo(() => {
        return (
            <div className="cursor-pointer mr-4" onClick={downloadToCSV}>
                <FontAwesomeIcon
                    icon={['fas', 'file-csv']}
                    className="text-blue-300"
                />
                <span className="text-blue-300 text-sm pl-2">
                    {t('common.components.ReportsTable.export-to-csv')}
                </span>
            </div>
        );
    }, []);

    const inputSearch = (
        <>
            <div className="mt-1 relative rounded-md shadow-sm">
                <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm">
                        <FontAwesomeIcon
                            icon={['far', 'search']}
                            fixedWidth
                            className="mr-1"
                        />
                    </span>
                </div>
                <input
                    value={searchValue}
                    onChange={(e) => handleInputSearchChange(e.target.value)}
                    autoComplete="off"
                    type="search"
                    name="budget"
                    id="search-table"
                    className={`focus:ring-0 focus:outline-none block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md ml-1`}
                    placeholder={t(
                        'sections.management.section.users.users.searchPlaceholder'
                    )}
                />
            </div>
        </>
    );

    const paginationOptions = {
        rowsPerPageText: t('sections.management.section.pricing.row-per-page'),
        rangeSeparatorText: t('sections.management.section.pricing.of'),
        noRowsPerPage: false,
    };

    return (
        <div
            className={`${
                isActive ? 'block' : 'hidden'
            } px-4 w-full md:w-10/12 relative`}>
            <div className="flex justify-between">
                <div>
                    <label className="block text-gray-700 text-sm font-bold mb-2">
                        {t(
                            'sections.management.section.users.users.customerTitle'
                        )}
                        <p className="font-light">
                            {t(
                                'sections.management.section.users.users.customerTxt'
                            )}
                        </p>
                    </label>
                </div>
                <div>
                    <button
                        className="group buttonNew"
                        onClick={toggleModalOpen}>
                        <FontAwesomeIcon
                            icon={['fas', 'plus']}
                            className="text-white"
                            onClick={toggleModalOpen}
                        />
                        <span
                            className="hidden group-hover:inline transition-opacity delay-300 group-hover:opacity-100 ml-4 font-light"
                            onClick={toggleModalOpen}>
                            {t(
                                'sections.management.section.users.users.createButton'
                            )}
                        </span>
                    </button>
                </div>
            </div>
            <div className="w-full relative overflow-y-auto">
                <div className="w-full flex justify-between items-center ">
                    <div className="md:w-2/6 md:mr-4">{inputSearch}</div>
                    {exportCSVButton}
                </div>
                <DataTable
                    className="w-full"
                    responsive={true}
                    dense={true}
                    keyField="id"
                    noDataComponent={false}
                    columns={columns}
                    noHeader={true}
                    data={users}
                    progressPending={isLoading}
                    paginationComponentOptions={paginationOptions}
                    actions={exportCSVButton}
                    progressComponent={
                        <>
                            <FontAwesomeIcon
                                icon={['fas', 'circle-notch']}
                                className="text-blue-400 animate-spin mx-2"
                            />
                        </>
                    }
                    pagination
                    paginationServer
                    paginationTotalRows={totalRows}
                    onChangeRowsPerPage={handlePerRowsChange}
                    onChangePage={handlePageChange}
                    onSort={(selectedColumn, sortDirection, sortedRows) =>
                        setCurrentSort({
                            sortby: selectedColumn.key,
                            sortDirection,
                        })
                    }
                />
            </div>
            {isModalOpen && (
                <UserModal
                    isActive={isModalOpen}
                    toggler={toggleModalOpen}
                    onSubmit={onSubmitUserModal}
                    user={user}
                    edit={isEditingUser}
                />
            )}
            <CSVLink
                filename={`users.csv`}
                headers={columns.filter((column) => column.exportCSV)}
                data={totalUsers}
                className={'hidden'}
                ref={csvLinkRef}
                target={'_blank'}
            />
        </div>
    );
};

export default UserList;
