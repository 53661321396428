import React from 'react';

const ModalBody = (props) => {
    const { children, className = '' } = props;

    return (
        <div
            className={`relative px-3 flex-auto ${className} overflow-visible text-gray-600 antialiased`}>
            {children}
        </div>
    );
};

export default ModalBody;
