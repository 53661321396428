import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import { newCampaignActions, notificationsActions } from '../../../../store';
import { guessTimezoneOfBrowser } from '../../../../utils/functions';
import SwitcherButton from '../../../Buttons/CommonSwitcher';
import DateRangerPickerWithCustomTimezone from '../../../DateRangerPickerWithCustomTimezone/DateRangerPickerWithCustomTimezone';
import DaysHours from './DaysHours/DaysHours';

const Timing = ({ service, timing, totalSpent, dispatch }) => {
    const { t } = useTranslation();
    // spending per api-real campaign, to be able to test: eg: totalSpent = 10
    const isDayParting = timing.isDayParting;
    const isAsap = timing.isAsap;
    const periodValue = timing.periodValue
        ? [new Date(timing.periodValue[0]), new Date(timing.periodValue[1])]
        : null;
    const selectedTimezone = timing.selectedTimezone;
    const timezones = timing.timezones;

    const selectedTimezoneCopy = Object.assign({}, selectedTimezone);
    const isNegativeUtc = (timezone) => {
        return timezone <= 0;
    };
    const timezonesCopy = [];

    timezones.forEach((timezone) => {
        const newTimezone = Object.assign({}, timezone);
        newTimezone.label = `${timezone.description} | (UTC ${
            isNegativeUtc(timezone.gmt) ? timezone.gmt : '+' + timezone.gmt
        })`;
        timezonesCopy.push(newTimezone);
    });

    const sortedTimezones = timezonesCopy.sort(function (a, b) {
        return a.gmt - b.gmt;
    });

    selectedTimezoneCopy.label = `${selectedTimezoneCopy.description} | (UTC ${
        isNegativeUtc(selectedTimezone.gmt)
            ? selectedTimezone.gmt
            : '+' + selectedTimezone.gmt
    })`;

    const change = (attribute, value) => {
        const newTiming = Object.assign({}, timing);
        newTiming[attribute] = value;
        dispatch(newCampaignActions.setTiming(newTiming));
    };

    const toggle = (attribute) => {
        change(attribute, !timing[attribute]);
    };
    //it sets as default the values for daysEnabled and hoursEnabled when toggle is deactived
    useEffect(() => {
        if (!isDayParting) {
            dispatch(
                newCampaignActions.setTiming({
                    ...timing,
                    daysEnabled: [],
                    hoursEnabled: [],
                })
            );
        }
    }, [isDayParting]);

    const handleChangePeriodValue = (period) => {
        const isSpent = !!totalSpent;
        const stampStartByCampaign = timing.periodValue;
        const isPeriodDefinedCampaign = !!timing.periodValue;

        const stampStartDate = period ? period[0].getTime() : '';
        const stampEndDate = period ? period[1].getTime() : '';
        const today = new Date().setHours(0, 0, 0, 0);
        let newPeriod;

        if (isSpent && isPeriodDefinedCampaign) {
            const stampEndByDateRanger = period ? period[1].getTime() : NaN;
            newPeriod = period
                ? [stampStartByCampaign[0], stampEndByDateRanger]
                : null;
        } else {
            //FIXME: Fix component
            if (stampStartDate >= today && stampEndDate >= today) {
                newPeriod = [stampStartDate, stampEndDate];
            } else if (stampEndDate >= today && stampStartDate <= today) {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t(
                            'sections.campaigns.new-campaign.wizard-basics.timing.description'
                        ),
                        message: t(
                            'sections.campaigns.new-campaign.wizard-basics.timing.old-start-date'
                        ),
                    })
                );
            } else {
                dispatch(
                    notificationsActions.setNotification({
                        type: 'warning',
                        title: t(
                            'sections.campaigns.new-campaign.wizard-basics.timing.description'
                        ),
                        message: t(
                            'sections.campaigns.new-campaign.wizard-basics.timing.old-start-date'
                        ),
                    })
                );
            }
        }
        change('periodValue', newPeriod);
    };

    useEffect(() => {
        const fetchData = async () => {
            let timezones = await service.getTimezones();
            change('timezones', timezones);
        };

        if (timezones.length === 0) fetchData();
    }, []);

    useEffect(() => {
        if (timezones.length > 0 && selectedTimezone === '') {
            const timezone = guessTimezoneOfBrowser(timezones);
            change('selectedTimezone', timezone);
        }
    }, [timezones]);

    return (
        <div className="module rounded m-1 mb-4">
            <div className="header flex justify-between content-center">
                <div className="title text-xl font-extralight">
                    {t(
                        'sections.campaigns.new-campaign.wizard-basics.timing.title'
                    )}
                </div>
            </div>
            <div className="body rounded-sm p-1">
                {/* From / To */}
                <div className={`flex justify-between`}>
                    <div className="w-3/6 pl-4">
                        <label className="block text-sm font-medium text-gray-700">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.timing.timezone'
                            )}
                        </label>
                        <div className={`w-5/6 mt-4`}>
                            <Select
                                value={selectedTimezoneCopy}
                                options={sortedTimezones}
                                onChange={(selectedTimezone) =>
                                    change('selectedTimezone', selectedTimezone)
                                }
                            />
                            <div className={`mt-4 text-xs text-gray-500`}>
                                {selectedTimezone !== '' &&
                                    selectedTimezone.long_description}
                            </div>
                        </div>
                    </div>
                    <div className="w-3/6 pl-4">
                        <label
                            htmlFor="period"
                            className="block text-sm font-medium text-gray-700">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.timing.description'
                            )}{' '}
                            <span className="font-light">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.timing.date-range-picker-label'
                                )}
                            </span>
                        </label>
                        <div
                            className="bg-transparent text-gray-500 sm:text-sm rounded-md mt-4"
                            onKeyDown={(event) => event.preventDefault()}>
                            <DateRangerPickerWithCustomTimezone
                                onChange={handleChangePeriodValue}
                                value={periodValue}
                                timezone={selectedTimezone}
                                totalSpent={totalSpent}
                                clearIcon={null}
                            />
                            {!!totalSpent && (
                                <span className="font-light block mt-4 text-sm text-gray-400">
                                    {t(
                                        'sections.campaigns.new-campaign.wizard-basics.timing.date-warning'
                                    )}
                                </span>
                            )}
                        </div>
                    </div>
                </div>
                {/* Pacing */}
                <div className="block my-2">
                    <div className="flex divide-x divide-dotted h-32">
                        <div className="w-3/6 pr-4">
                            <label className="block text-sm font-medium text-gray-700">
                                {t(
                                    'sections.campaigns.new-campaign.wizard-basics.timing.pacing'
                                )}
                            </label>
                            <div className="w-2/6 mt-4">
                                <SwitcherButton
                                    revertLabel={true}
                                    onToggle={() => toggle('isAsap')}
                                    status={isAsap}
                                    labelOn={t(
                                        'sections.campaigns.new-campaign.wizard-basics.timing.ASAP'
                                    )}
                                    labelOff={t(
                                        'sections.campaigns.new-campaign.wizard-basics.timing.evenly'
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between">
                    {/* Day parting label, show when put CustomTableDragSelect
                    <label htmlFor="day-parting" className="block text-sm font-medium text-gray-700">{t("sections.campaigns.new-campaign.wizard-basics.timing.day-parting")} <span className="font-light">{t("sections.campaigns.new-campaign.wizard-basics.timing.day-parting-description")}</span></label> */}
                    <label
                        htmlFor="day-parting"
                        className="block text-sm font-medium text-gray-700">
                        {t(
                            'sections.campaigns.new-campaign.wizard-basics.timing.days'
                        )}{' '}
                        <span className="font-light">
                            {t(
                                'sections.campaigns.new-campaign.wizard-basics.timing.of-the-week'
                            )}
                        </span>
                    </label>
                    <div className="brief">
                        <SwitcherButton
                            onToggle={() => toggle('isDayParting')}
                            status={isDayParting}
                            labelOn={t('common.buttons.label-active')}
                            labelOff={t('common.buttons.label-inactive')}
                        />
                    </div>
                </div>
                {isDayParting && (
                    <>
                        {/* When backend and audiences accept matrix of days and hours we should use this component
                    and validate from validation issues
                    <CustomTableDragSelect change={change}/> */}
                        <DaysHours change={change} />
                    </>
                )}
            </div>
        </div>
    );
};

export default Timing;
